import React, { useRef } from "react";
import styled from "styled-components";
// Assets
import ContactImg1 from "../../assets/img/contact-1.png";
import ContactImg2 from "../../assets/img/contact-2.png";
import ContactImg3 from "../../assets/img/contact-3.png";
import emailjs from "emailjs-com";

export default function Contact() {
  const form = useRef();

  const sendEmail = (e) => {
    console.log("*******");

    e.preventDefault();

    emailjs
      .sendForm(
        "YOUR_SERVICE_ID", // Replace with your EmailJS Service ID
        "d-44bbbf59a9f94f67b74a90c2dd569957", // Replace with your EmailJS Template ID
        form.current,
        "YOUR_USER_ID" // Replace with your EmailJS User ID
      )
      .then(
        (result) => {
          alert("Message sent successfully!");
        },
        (error) => {
          alert("An error occurred, please try again later.");
        }
      );
  };

  return (
    <Wrapper id='contact'>
      <div>
        <div className='container'>
          <HeaderInfo>
            <h1 className='font40 extraBold'>Let's get in touch</h1>
          </HeaderInfo>
          <div
            className='row'
            style={{ paddingBottom: "30px" }}>
            <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6'>
              <Form
                ref={form}
                onSubmit={sendEmail}>
                <label className='font13'>First name:</label>
                <input
                  type='text'
                  id='fname'
                  name='fname'
                  className='font20 extraBold'
                />
                <label className='font13'>Email:</label>
                <input
                  type='text'
                  id='email'
                  name='email'
                  className='font20 extraBold'
                />
                <label className='font13'>Subject:</label>
                <input
                  type='text'
                  id='subject'
                  name='subject'
                  className='font20 extraBold'
                />
                <textarea
                  rows='4'
                  cols='50'
                  type='text'
                  id='message'
                  name='message'
                  className='font20 extraBold'
                />
              </Form>
              <SumbitWrapper className='flex'>
                <ButtonInput
                  type='submit'
                  value='Send Message'
                  className='pointer animate radius8'
                  style={{ maxWidth: "220px" }}
                />
              </SumbitWrapper>
            </div>
            <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6 flex'>
              <div
                style={{ width: "50%", overflow: "hidden" }}
                className='flexNullCenter flexColumn'>
                <ContactImgBox>
                  <img
                    style={{ overflow: "hidden", maxHeight: "204px" }}
                    src={ContactImg1}
                    alt='office'
                    className='radius6'
                  />
                </ContactImgBox>
                <ContactImgBox>
                  <img
                    style={{ overflow: "hidden", maxHeight: "295px" }}
                    src={ContactImg2}
                    alt='office'
                    className='radius6'
                  />
                </ContactImgBox>
              </div>
              <div style={{ width: "50%", overflow: "hidden" }}>
                <div style={{ marginTop: "100px", overflow: "hidden" }}>
                  <img
                    style={{ overflow: "hidden", maxWidth: "278px" }}
                    src={ContactImg3}
                    alt='office'
                    className='radius6'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
  border: 1px solid #ffab03;
  background-color: #ffab03;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #ffab03d6;
    border: 1px solid #ffab03;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const ContactImgBox = styled.div`
  max-width: 180px;
  align-self: flex-end;
  margin: 10px 30px 10px 0;
  overflow: hidden !important;
`;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;
