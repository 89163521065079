import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
// Components
import TestimonialBox from "../Elements/TestimonialBox";

export default function TestimonialSlider() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <div className='row textCenter'>
        <div className='col-xs-12 col-sm-4 col-md-4 col-lg-4'>
          <TestimonialBox
            title='1'
            text='st'
            step='Register as a product owner'
            action={() => alert("Step 1: Register as a product owner")}
          />
        </div>
        <div className='col-xs-12 col-sm-4 col-md-4 col-lg-4'>
          <TestimonialBox
            title='2'
            text='nd'
            step='Link your software product to RoSaaS'
            action={() => alert("Step 2: Link your software product")}
            variant='light'
          />
        </div>
        <div className='col-xs-12 col-sm-4 col-md-4 col-lg-4'>
          <TestimonialBox
            title='3'
            text='rd'
            step='Define your products, plans, and offers'
            action={() =>
              alert("Step 3: Define your products, plans, and offers")
            }
          />
        </div>
      </div>
      <div className='row textCenter'>
        <div className='col-xs-12 col-sm-4 col-md-4 col-lg-4'>
          <TestimonialBox
            title='4'
            text='th'
            step='Publish the plans to your website'
            action={() => alert("Step 4: Publish the plans to your website")}
            variant='light'
          />
        </div>
        <div className='col-xs-12 col-sm-4 col-md-4 col-lg-4'>
          <TestimonialBox
            title='5'
            text='th'
            tag='guide'
            step='Accept subscriptions and payments'
            action={() => alert("Step 5: Accept subscriptions and payments")}
          />
        </div>
        <div className='col-xs-12 col-sm-4 col-md-4 col-lg-4'>
          <TestimonialBox
            title='6'
            text='th'
            tag='guide'
            step='Monitor traction through reports and dashboards'
            action={() => alert("Step 6: Monitor traction")}
            variant='light'
          />
        </div>
      </div>
    </div>
  );
}

const LogoWrapper = styled.div`
  width: 90%;
  padding: 0 5%;
  cursor: pointer;
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;
