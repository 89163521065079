import React from "react";
import styled from "styled-components";
// Components
import BlogBox from "../Elements/BlogBox";
import FullButton from "../Buttons/FullButton";
import TestimonialSlider from "../Elements/TestimonialSlider";
import TestimonialBox from "../Elements/TestimonialBox";

export default function Blog() {
  return (
    <Wrapper id='howto'>
      <div className='whiteBg'>
        <div className='container'>
          <HeaderInfo>
            <h1 className='font40 extraBold'>How To</h1>
            <p className='font13'>
              Follow these simple steps to get started with RoSaaS and make the
              most out of your product management experience.
            </p>
          </HeaderInfo>

          <div className='row textCenter'>
            <div className='col-xs-12 col-sm-4 col-md-4 col-lg-4'>
              <TestimonialBox
                title='1'
                text='st'
                step='Register as a product owner'
                action={() => alert("Step 1: Register as a product owner")}
              />
            </div>
            <div className='col-xs-12 col-sm-4 col-md-4 col-lg-4'>
              <TestimonialBox
                title='2'
                text='nd'
                step='Link your software product to RoSaaS'
                action={() => alert("Step 2: Link your software product")}
                variant='light'
              />
            </div>
            <div className='col-xs-12 col-sm-4 col-md-4 col-lg-4'>
              <TestimonialBox
                title='3'
                text='rd'
                step='Define your products, plans, and offers'
                action={() =>
                  alert("Step 3: Define your products, plans, and offers")
                }
              />
            </div>
          </div>
          <div className='row textCenter'>
            <div className='col-xs-12 col-sm-4 col-md-4 col-lg-4'>
              <TestimonialBox
                title='4'
                text='th'
                step='Publish the plans to your website'
                action={() =>
                  alert("Step 4: Publish the plans to your website")
                }
                variant='light'
              />
            </div>
            <div className='col-xs-12 col-sm-4 col-md-4 col-lg-4'>
              <TestimonialBox
                title='5'
                text='th'
                tag='guide'
                step='Accept subscriptions and payments'
                action={() =>
                  alert("Step 5: Accept subscriptions and payments")
                }
              />
            </div>
            <div className='col-xs-12 col-sm-4 col-md-4 col-lg-4'>
              <TestimonialBox
                title='6'
                text='th'
                tag='guide'
                step='Monitor traction through reports and dashboards'
                action={() => alert("Step 6: Monitor traction")}
                variant='light'
              />
            </div>
          </div>
          <div className='row flexCenter'>
            <div style={{ margin: "50px 0", width: "200px" }}>
              <FullButton
                title='Learn More'
                action={() => window.open("https://docs.rosaas.app")}
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
