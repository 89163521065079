import * as React from "react";
import LogoSvg from "../../assets/svg/logo.svg";

function SvgComponent(props) {
  return (
    <img
      src={LogoSvg}
      alt='Logo'
      width={props.width || 27} // Set default width or use passed props
      height={props.height || 40} // Set default height or use passed props
      {...props}
    />
  );
}

export default SvgComponent;
