import React from "react";
import styled from "styled-components";
// Components
import ClientSlider from "../Elements/ClientSlider";
import ServiceBox from "../Elements/ServiceBox";
import FullButton from "../Buttons/FullButton";
// Font Awesome Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBookOpen,
  faLanguage,
  faMoneyBillWave,
  faPlug,
  faShieldAlt,
  faUserCheck,
} from "@fortawesome/free-solid-svg-icons";

export default function Services() {
  return (
    <Wrapper id='features'>
      <div
        className='lightBg'
        style={{ padding: "50px 0" }}>
        <div className='container'>
          <ClientSlider />
        </div>
      </div>
      <div
        className='whiteBg'
        style={{ padding: "60px 0" }}>
        <div className='container'>
          <HeaderInfo>
            <h1 className='font40 extraBold'>Features</h1>
          </HeaderInfo>
          <ServiceBoxRow className='flex'>
            <ServiceBoxWrapper>
              <ServiceBox
                icon={<FontAwesomeIcon icon={faUserCheck} />}
                title='User friendly'
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon={
                  <FontAwesomeIcon
                    className='secondColor'
                    icon={faPlug}
                  />
                }
                title='Easy to integrate'
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon={<FontAwesomeIcon icon={faBookOpen} />}
                title='Detailed documentation and tutorials'
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon={
                  <FontAwesomeIcon
                    className='secondColor'
                    icon={faLanguage}
                  />
                }
                title='Multilingual'
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon={<FontAwesomeIcon icon={faMoneyBillWave} />}
                title='Multiple currencies'
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon={
                  <FontAwesomeIcon
                    className='secondColor'
                    icon={faShieldAlt}
                  />
                }
                title='Safe and secure'
              />
            </ServiceBoxWrapper>
          </ServiceBoxRow>
          <div className='row flexCenter'>
            <div
              style={{
                marginTop: "40px",
                marginBottom: "90px",
                width: "200px",
              }}>
              <FullButton
                title='Start Now'
                action={() =>
                  (window.location.href =
                    "https://dashboard.rosaas.app/marketplace/roaa-tech/rosaas")
                }
              />
            </div>
          </div>
        </div>
        <div className='lightBg'>
          <div
            className='lightBg'
            style={{ padding: "50px 0" }}>
            <div className='container'>
              <div className='flexCenter'>
                <HeaderInfo>
                  <h1 className='font40 extraBold'>
                    Want to start selling your products? Let RoSaaS assist!
                  </h1>
                </HeaderInfo>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;

const ServiceBoxRow = styled.div`
  display: flex; // Enable flexbox
  flex-wrap: wrap; // Allow items to wrap to the next line
  justify-content: space-between; // Space between items
`;

const ServiceBoxWrapper = styled.div`
  width: calc(33.33% - 10px); // 3 columns layout
  margin-bottom: 30px; // Space between rows
  padding-top: 80px; // Top padding for boxes

  @media (max-width: 860px) {
    width: 100%; // Full width on small screens
    text-align: center; // Center text
    padding-top: 40px; // Reduced padding for small screens
  }
`;

const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center; // Center the header info on small screens
  }
`;
