import React from "react";
import styled from "styled-components";
// Assets
import RollerIcon from "../../assets/svg/Services/RollerIcon";
import MonitorIcon from "../../assets/svg/Services/MonitorIcon";
import BrowserIcon from "../../assets/svg/Services/BrowserIcon";
import PrinterIcon from "../../assets/svg/Services/PrinterIcon";

export default function ServiceBox({ icon, title }) {
  return (
    <Wrapper className='flex flexColumn flexCenter'>
      <IconStyle>{icon}</IconStyle>
      <TitleStyle className='font15 extraBold'>{title}</TitleStyle>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const IconStyle = styled.div`
  width: 40px; // Set a fixed width
  height: 40px; // Set a fixed height
  display: flex; // Use flexbox to center the icon
  justify-content: center; // Center horizontally
  align-items: center; // Center vertically
  svg {
    width: 100%; /* Ensure the SVG scales to the size of the container */
    height: 100%; /* Ensure the SVG scales to the size of the container */
  }
  @media (max-width: 860px) {
    margin: 0 auto;
  }
`;
const TitleStyle = styled.h2`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  padding-top: 40px;
  display: flex; // Use flexbox to center the icon
  justify-content: center; // Center horizontally
  align-items: center; // Center vertically

  @media (max-width: 860px) {
    padding-top: 20px;
  }
`;
const SubtitleStyle = styled.p`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
`;
