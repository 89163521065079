import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { IntlProvider } from "react-intl";
import Landing from "./screens/Landing.jsx";
import messages_en from "./locales/en.json";
import messages_ar from "./locales/ar.json";

const messages = {
  en: messages_en,
  ar: messages_ar,
};

export default function App() {
  const [locale, setLocale] = useState("en"); // default locale
  const [direction, setDirection] = useState("ltr"); // default direction

  useEffect(() => {
    // Retrieve stored language from local storage
    const storedLocale = localStorage.getItem("locale");
    if (storedLocale) {
      setLocale(storedLocale);
      setDirection(storedLocale === "ar" ? "rtl" : "ltr");
    }
  }, []);

  const switchLanguage = (lang) => {
    setLocale(lang);
    setDirection(lang === "ar" ? "rtl" : "ltr");
    localStorage.setItem("locale", lang);
  };

  return (
    <IntlProvider
      locale={locale}
      messages={messages[locale]}>
      <Helmet>
        <link
          rel='preconnect'
          href='https://fonts.googleapis.com'
        />
        <link
          rel='preconnect'
          href='https://fonts.gstatic.com'
          crossOrigin
        />
        <link
          href='https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap'
          rel='stylesheet'
        />
      </Helmet>
      <div dir={direction}>
        <Landing switchLanguage={switchLanguage} />
      </div>
    </IntlProvider>
  );
}
