import React from "react";
import styled from "styled-components";

// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
import Services from "../components/Sections/Services";
import Projects from "../components/Sections/Projects";
import Blog from "../components/Sections/Blog";
import Pricing from "../components/Sections/Pricing";
import Contact from "../components/Sections/Contact";
import Footer from "../components/Sections/Footer";

export default function Landing() {
  return (
    <Wrapper>
      <TopNavbar />
      <Header />
      <Services />
      {/* <Projects /> */}
      <Blog />
      <Pricing />
      <Contact />
      <Footer />
    </Wrapper>
  );
}
const Wrapper = styled.div`
  color: #414042;
`;
