import React from "react";
import styled from "styled-components";

export default function BlogBox({ title, text, step }) {
  return (
    <WrapperBtn className='animate'>
      <Wrapper className='whiteBg radius8 shadow'>
        <h3 className='font20 extraBold'>
          <Title className='font30'>{title}</Title>
          <TextStep>{text}</TextStep>
        </h3>
        <Step className='font13 extraBold'>{step}</Step>
      </Wrapper>
    </WrapperBtn>
  );
}

const Wrapper = styled.div`
  width: 100%;
  text-align: left;
  padding: 20px 30px;
`;

const WrapperBtn = styled.button`
  border: 0;
  background-color: #ffab0358;
  margin: 10px;
  border-radius: 20px;
  outline: none;

  :hover {
    opacity: 0.7;
  }
`;

const Title = styled.span`
  color: #ffab03d6;
  padding: 0; // No padding on the title
  margin: 0; // No margin on the title
`;

const TextStep = styled.span`
  padding: 0; // No padding on the TextStep
  margin: 0; // No margin to avoid space with the title
`;

const Step = styled.span`
  padding-top: 10px; // Add padding at the top of Step (adjust as needed)
  display: block; // Ensure it takes full width
`;
