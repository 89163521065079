import React from "react";
import styled from "styled-components";
// Components
import PricingTable from "../Elements/PricingTable";
import FullButton from "../Buttons/FullButton";

export default function Pricing() {
  return (
    <Wrapper id='pricing'>
      <div
        className='lightBg'
        style={{ padding: "50px 0", paddingBottom: "20px" }}>
        <div className='container'>
          <HeaderInfo>
            <h1 className='font40 extraBold'>Pricing</h1>
            <p className='font13'>
              <br />
              <div className='row flexColumnCenter'>
                <h1 className='font30 extraBold'>Free Trial !</h1>
                <div style={{ marginTop: "20px", width: "200px" }}>
                  <FullButton
                    title='Start Now'
                    action={() =>
                      (window.location.href =
                        "https://dashboard.rosaas.app/marketplace/roaa-tech/rosaas")
                    }
                  />
                </div>
              </div>
            </p>
          </HeaderInfo>
          {/* <TablesWrapper className="flexSpaceNull">
            <TableBox>
              <PricingTable
                icon="roller"
                price="$29,99/mo"
                title="Starter"
                text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                offers={[
                  { name: "Product Offer", cheked: true },
                  { name: "Offer", cheked: true },
                  { name: "Product Offer #2", cheked: false },
                  { name: "Product", cheked: false },
                  { name: "Product Offer", cheked: false },
                ]}
                action={() => alert("clicked")}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon="monitor"
                price="$49,99/mo"
                title="Basic"
                text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                offers={[
                  { name: "Product Offer", cheked: true },
                  { name: "Offer", cheked: true },
                  { name: "Product Offer #2", cheked: true },
                  { name: "Product", cheked: true },
                  { name: "Product Offer", cheked: false },
                ]}
                action={() => alert("clicked")}
              />
            </TableBox>
            <TableBox>
              <PricingTable
                icon="browser"
                price="$59,99/mo"
                title="Golden"
                text="Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor."
                offers={[
                  { name: "Product Offer", cheked: true },
                  { name: "Offer", cheked: true },
                  { name: "Product Offer #2", cheked: true },
                  { name: "Product", cheked: true },
                  { name: "Product Offer", cheked: true },
                ]}
                action={() => alert("clicked")}
              />
            </TableBox>
          </TablesWrapper> */}
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
`;
const HeaderInfo = styled.div`
  .flexColumnCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  margin-bottom: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const TablesWrapper = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const TableBox = styled.div`
  width: 31%;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto;
  }
`;
