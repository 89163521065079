import React from "react";
import styled from "styled-components";
// Assets
import QuoteIcon from "../../assets/svg/Quotes";

export default function TestimonialBox({ title, text, step, variant }) {
  return (
    <>
      <Wrapper
        variant={variant}
        className='darkBg radius8 '>
        <QuoteWrapper>
          <h2 className=' extraBold'>
            <Title className='font40'>{title}</Title>
            <TextStep className='font30'>{text}</TextStep>
          </h2>
        </QuoteWrapper>
        <p
          className='whiteColor font19'
          style={{
            paddingBottom: "30px",

            paddingTop: "0px",

            marginTop: "-35px",
            color: variant === "light" && "#414042",
          }}>
          {step}
        </p>
      </Wrapper>
    </>
  );
}
const Wrapper = styled.div`
  width: 100%;
  padding: 20px 7px;
  margin-top: 30px;
  background-color: ${({ variant }) =>
    variant === "light" ? "#ededed" : "#5e5e5ed1"};
`;

const QuoteWrapper = styled.div`
  position: relative;
  top: -50px;
  color: ${({ variant }) => (variant === "light" ? "#414042" : "#ffab03")};
`;

const Title = styled.span`
  color: #ffab03d6;
  padding: 0; // No padding on the title
  margin: 0; // No margin on the title
`;

const TextStep = styled.span`
  padding: 0; // No padding on the TextStep
  margin: 0; // No margin to avoid space with the title
`;
