import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Assets
import CloseIcon from "../../assets/svg/CloseIcon";
import LogoIcon from "../../assets/svg/Logo";

export default function Sidebar({ sidebarOpen, toggleSidebar }) {
  return (
    <Wrapper
      className='animate darkBg'
      sidebarOpen={sidebarOpen}>
      <SidebarHeader className='flexSpaceCenter'>
        <div className='flexNullCenter'>
          <LogoIcon />
          <h1
            className='whiteColor font20'
            style={{ marginLeft: "15px" }}>
            RoSaaS
          </h1>
        </div>
        <CloseBtn
          onClick={() => toggleSidebar(!sidebarOpen)}
          className='animate pointer'>
          <CloseIcon />
        </CloseBtn>
      </SidebarHeader>

      <UlStyle className='flexNullCenter flexColumn'>
        <li className='semiBold font15 pointer'>
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass='active'
            className='whiteColor'
            style={{ padding: "10px 15px" }}
            to='home'
            spy={true}
            smooth={true}
            offset={-60}>
            Home
          </Link>
        </li>
        <li className='semiBold font15 pointer'>
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass='active'
            className='whiteColor'
            style={{ padding: "10px 15px" }}
            to='features'
            spy={true}
            smooth={true}
            offset={-60}>
            Features
          </Link>
        </li>

        <li className='semiBold font15 pointer'>
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass='active'
            className='whiteColor'
            style={{ padding: "10px 15px" }}
            to='howto'
            spy={true}
            smooth={true}
            offset={-60}>
            How To
          </Link>
        </li>
        <li className='semiBold font15 pointer'>
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass='active'
            className='whiteColor'
            style={{ padding: "10px 15px" }}
            to='pricing'
            spy={true}
            smooth={true}
            offset={-60}>
            Pricing
          </Link>
        </li>
        <li className='semiBold font15 pointer'>
          <Link
            onClick={() => toggleSidebar(!sidebarOpen)}
            activeClass='active'
            className='whiteColor'
            style={{ padding: "10px 15px" }}
            to='contact'
            spy={true}
            smooth={true}
            offset={-60}>
            Contact
          </Link>
        </li>
      </UlStyle>
      <UlStyle className='flexSpaceCenter'>
        <li className='semiBold font15 pointer'>
          <a
            href='https://dashboard.rosaas.app/product-management/sign-in'
            style={{ padding: "10px 30px 10px 0" }}
            className='whiteColor'>
            Log in
          </a>
        </li>
        <li className='semiBold font15 pointer flexCenter'>
          <a
            href='https://dashboard.rosaas.app/marketplace/roaa-tech/rosaas'
            className='radius8 lightBg'
            style={{ padding: "10px 15px" }}>
            Get Started
          </a>
        </li>
      </UlStyle>
    </Wrapper>
  );
}

const Wrapper = styled.nav`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: fixed;
  top: 0;
  padding: 0 30px;
  display: ${(props) => !props.sidebarOpen && "none"};
  right: ${(props) => (props.sidebarOpen ? "0px" : "-400px")};
  z-index: 9999;
  @media (max-width: 400px) {
    width: 100%;
  }
`;
const SidebarHeader = styled.div`
  padding: 20px 0;
`;
const CloseBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  padding: 10px;
`;
const UlStyle = styled.ul`
  padding: 40px;
  li {
    margin: 20px 0;
  }
`;
